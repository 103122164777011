import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import Transparent from './images/transparent.gif'
import Pagination from 'react-js-pagination';
import Loading from './images/loading40.gif'

const cookies = new Cookies();



export default function FancyBook(props) {
  const [allLog, setallLog] = useState([]);
  const [refresh, setrefresh] = useState(true);

  var eventId = props.eventId

  console.log(props.eventId, 'eventId');

  useEffect(() => {
    var ssid = cookies.get('sid');
    if (!ssid) return;
    axios.post('https://flagapi.in/agentapi/newagentFancyExposure', {
      sid: ssid,
      eventId: props.eventId,
      selectionId: props.selectionId


    }).then(result => {
      setrefresh(false);
      if (result.status === 200) {

        var res = [];
        for (let key in result.data) {

          if (result.data.hasOwnProperty(key)) {
            let obj = {};
            obj.run = key;
            obj.position = result.data[key];

            res.push(obj);


          }
        }

        // console.log("ompklenght:-",res[1].position)
        setallLog(res);

      }

    }
    ).catch(e => {
      //setIsError(true);
    });
  }, [])





  return (

    <div id="agentlog" class="pop_bg" style={{ top: '0', display: 'block' }}>
      {/*<body class="betbuzz365">*/}




      {refresh && <div id="loading" class="loading-wrap" style={{}}>
        <ul class="loading">
          <li>
            <img src={Loading} />
          </li>
          <li>Loading...</li>
        </ul>
      </div>}


      <div class="pop-content">





        <div id="logWrap" class="log-wrap" style={{ marginTop: '8vh', width: "600px" }} >
          <table class="game-team">
            <tbody>
              <tr>
                <td class="game-name">
                  {props.marketname}
                  <a onClick={() => { props.changeDownlineLive(); }} class="pop-close" >
                    <img class="icon-back" src={Transparent} />Close</a>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pop-content">
            <table id="table" class="table01 tab-depth">
              <tr>
                <th width="50%" class="align-L">Runs</th>
                <th width="50%" class="border-l">Amount</th>
              </tr>
              {allLog.map((item, index) => {
                return (
                  <tr key={index} id="trTemp">
                    <td id="runs" className={`align-L ${item.position >= 0 ? "back" : "lay"}`}>{item.run}</td>
                    <td id="exposure" className={`${item.position >= 0 ? "back" : "lay"}`}>{item.position >= 0 ? parseFloat(item.position).toFixed(2) : '(' + parseFloat(Math.abs(item.position)).toFixed(2) + ')'}</td>
                  </tr>
                )
              })}
            </table>
          </div>
        </div>



      </div>
    </div>




  )
}






