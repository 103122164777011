import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Transparent from './images/transparent.gif'
import Pagination from 'react-js-pagination';
import DatePicker from "react-datepicker";
import Loading from './images/loading40.gif'


import "react-datepicker/dist/react-datepicker.css";

const cookies = new Cookies();
window.bet_day = 3;
window.betlistView = 1;
toast.configure()

export default function Myshare(props) {
    const [MatchList, setMatchList] = useState([]);
    const [Downlinelistagent, setDownlinelistagent] = useState([]);
    const [Collectionreposrt, setCollectionreposrt] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [uplineid, setuplineid] = useState();
    const [uplinepl, setuplinepl] = useState(0);
    const [postsPerPage] = useState(10);
    const [balance_update, setbalance_update] = useState(false);
    
    const [EventType, seteventType] = useState('100');
    const [Myshare, setMyshare] = useState('100');
    const [Comshare, setComshare] = useState('100');
    const [Matcom, setMatcom] = useState('100');
    const [Sesscom, setSesscom] = useState('100');
    const [showledger, setshowledger] = useState(0);
    const [showlenadena, setshowlenadena] = useState(0);
    const [openmasteruplinepl, setopenmasteruplinepl] = useState(0);
    const [showsettlement, setshowsettlement] = useState(0);
    const [Trans_histor, setTrans_histor] = useState([]);
    const [Paymode, setPaymode] = useState("");
    const [dlbl, setdlbl] = useState(0);
    const [AgentId, setAgentId] = useState(0);

    const [betStatus, setbetStatus] = useState('2');
    const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
    const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
    const [startDate, setStartDate] = useState(moment().toDate());
    const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
    const [refresh, setrefresh] = useState(false);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = MatchList.slice(indexOfFirstPost, indexOfLastPost);

    const bal_update=()=>{
        setbalance_update(!balance_update);
    }

    useEffect(() => {
        var ssid = cookies.get('sid');
        if (!ssid) return;
        setrefresh(true);
        axios.post('https://flagapi.in/agentapi/myshareagentinfo', {
            sid: ssid,
            User: props.user,
            levelno: props.levelNo
        }).then(result => {
            setrefresh(false);
            if (result.status === 200) {
                console.log("ompk", result.data)
                setMyshare(result.data[0].share);
                setComshare(result.data[0].myShare);
                setMatcom(result.data[0].matchComm);
                setSesscom(result.data[0].sessionComm);

            }
        }).catch(e => { });

        axios.post('https://flagapi.in/agentapi/downline_shareagentinfo', {
            sid: ssid,
            User: props.user,
        }).then(result => {
            setrefresh(false);
            if (result.status === 200) {
                setDownlinelistagent(result.data);
            }
        }).catch(e => { });

        axios.post('https://flagapi.in/agentapi/agentInfo', {
            sid: ssid,
        }).then(result => {
            setrefresh(false);
            if (result.status === 200) {
                setuplineid(result.data.uplinkInfo);
                setuplinepl(result.data.aboveBalance);
                if (result.data.level === 5) { setopenmasteruplinepl(1) }

            }
        }).catch(e => { });

        axios.post('https://flagapi.in/agentapi/CollectionReport', {
            sid: ssid,
            id: props.user,
            level: props.levelNo
        }).then(result => {
            setrefresh(false);
            if (result.status === 200) {
                setCollectionreposrt(result.data);
            }
        }).catch(e => { });





    }, [ props.user,props.levelNo,balance_update])

    const changeEvent = (e) => {
        var selectBox = document.getElementById("func_sports");
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        seteventType(selectedValue);

    }
         
    
    const fun_paymode = (e, id2) => {

        var selectBox = document.getElementById("mode" + id2);
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        // setPaymode(selectedValue);

        if (selectedValue == 1) { console.log("online"); setPaymode("online"); }
        if (selectedValue == 2) { console.log("Cash"); setPaymode("Cash"); }

    }

   const fun_gethistory = (id,id3)=>{
    var ssid = cookies.get('sid');
    setAgentId(id);
    axios.post('https://flagapi.in/agentapi/agentBalanceHistory', {
            sid: ssid,
            agentId:id.toLowerCase()
          
        }).then(result => {
            setrefresh(false);
            if (result.status === 200) {
                setTrans_histor(result.data);
            }
        }).catch(e => { });
   }


    const fun_settle_bal_downline = (id, id2) => {
        var bal = document.getElementById("settle" + id2).value;
        var ssid = cookies.get('sid');
        console.log("val", bal, id, Paymode)
        if (bal) {
            if (Paymode) {
                axios.post('https://flagapi.in/agentapi/updateAgentBalance', {
                    sid: ssid,
                    agentId: id.toLowerCase(),
                    amount: bal,
                    remark: Paymode
                }).then(result => {
                    setrefresh(false);
                    if (result.status === 200) {
                        toast.warn('selltement Successfully ', { position: toast.POSITION.TOP_CENTER })
                        document.getElementById("settle" + id2).value="";
                        bal_update();
                    }
                  
                    setrefresh(false);
                }).catch(e => { });

            }
            else {
                toast.warn('Enter Settlement amount !!', { position: toast.POSITION.TOP_CENTER })
                document.getElementById("settle" + id2).value="";
                bal_update();
            }
        }
        else {
            toast.warn('Select Payment Mode!!', { position: toast.POSITION.TOP_CENTER })
            bal_update();
        }
    }









    return (
        <React.Fragment>
            <div class="full-wrap" style={{ height: 'calc(100%)' }}>
                {/* <!-- Center Column --> */}
                <div class="over-wrap" style={MatchList.length === 0 ? { height: 'inherit' } : { height: 'fit-content' }} >
                    {/* <!-- Loading Wrap --> */}
                    {refresh && <div id="loading" class="loading-wrap" style={{}}>
                        <ul class="loading">
                            <li>
                                <img src={Loading} />
                            </li>
                            <li>Loading...</li>
                        </ul>
                    </div>}

                    <div style={{ height: '50px', width: '100%', backgroundColor: '#e0e6e6' }}>
                        <div class="mysharehead" >
                            <div class="mysharedown1" >My Share</div><div class="mysharedown2"> {Myshare}%</div>
                        </div>
                        <div class="mysharehead" >
                            <div class="mysharedown1"  >Company Share</div><div class="mysharedown2"  >{100 - Myshare}%</div>
                        </div>
                        <div class="mysharehead" >
                            <div class="mysharedown1"  >Match Commision</div><div class="mysharedown2" >{Matcom}%</div>
                        </div>
                        <div class="mysharehead" >
                            <div class="mysharedown1"  >Session Commision</div><div class="mysharedown2" >{Sesscom}%</div>
                        </div>

                    </div>
                    <div style={{ height: '500px', width: '100%' }}>
                        <div style={{ width: '12.5%', float: 'left' }}>
                            <table id="table_transfer" class="table01 tab-transfer tab-banking" style={{ textAlign: 'left' }}>
                                <tbody>
                                    <tr> <th width="10%">Ledger</th></tr>
                                </tbody>
                                <tbody id="content"><tr id="akshayddl" main_userid="akshayddl" onClick={() => { setshowledger(1); setshowlenadena(0); setshowsettlement(0) }}>
                                    <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >My Ledger</td> </tr> </tbody>
                                <tbody id="content"><tr id="akshayddl" main_userid="akshayddl" onClick={() => { setshowledger(0); setshowlenadena(1); setshowsettlement(0) }}>
                                    <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }}>Lena & Dena</td> </tr> </tbody>
                            </table>


                        </div>
                        <div style={{ height: '550px', width: '87.5%', float: 'Right', backgroundColor: 'white' }}>
                            {showledger === 1 &&
                                <div style={{ height: '500px', width: '100%' }}>
                                    <div style={{ height: '100px', width: '100%', paddingLeft: '10px' }}>
                                        <h2>Company Lena Dena</h2>

                                        <div class="function-wrap">
                                            <ul class="input-list">
                                                <div id="statusCondition" style={{ display: 'none' }}>
                                                    <li><label>Bet Status:</label></li>
                                                    <li>
                                                        <select name="betStatus" id="betStatus">
                                                        </select>
                                                    </li>
                                                </div>
                                                <li><label>Entry Type</label></li>
                                                <li>
                                                    <select id="func_sports" onChange={(e) => { changeEvent(e); }}>
                                                        <option value="100" selected="selected">All</option>
                                                        <option value="1">All</option>
                                                        <option value="2">Settlement</option>

                                                    </select>
                                                </li>
                                                <li><label>Period</label>
                                                </li>
                                                <li class="input-list" style={{ display: 'inline-flex' }}>

                                                    <DatePicker
                                                        selectsStart
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={startDate}
                                                        placeholderText="YYYY-MM-DD"
                                                        className="cal-input"
                                                        onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }}
                                                    />
                                                    <input id="startTime" disabled="true" class="time-input disable" type="text" placeholder="09:00" maxlength="5" />

                                                    <DatePicker
                                                        selectsEnd
                                                        dateFormat="yyyy-MM-dd"
                                                        placeholderText="YYYY-MM-DD"
                                                        selected={endDate}
                                                        onChange={(date) => { setendDate(date); seteDate(moment(date).format("YYYY-MM-DD")); }}
                                                        className="cal-input"
                                                    />
                                                    <input id="endTime" disabled="true" class="time-input disable" type="text" placeholder="08:59" maxlength="5" />


                                                </li>
                                                <li><a id="getPL" class="btn-send">Get P &amp; L</a></li>



                                            </ul>


                                        </div>
                                        <div style={{ height: '40px', width: '100%', backgroundColor: 'whitesmoke' }}>
                                            <ul>
                                                <li style={{ display: 'inline', fontSize: '20px', fontWeight: 'bold' }}>Lena : </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <li style={{ display: 'inline' }}><input style={{ border: '1px solid gray', fontSize: '12px', fontWeight: 'bold' }} value="0.00"></input></li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <li style={{ display: 'inline', fontSize: '20px', fontWeight: 'bold' }}>Dena : </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <li style={{ display: 'inline' }}><input style={{ border: '1px solid gray', fontSize: '12px', fontWeight: 'bold' }} value="0.00"></input></li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <li style={{ display: 'inline', fontSize: '20px', fontWeight: 'bold' }}>Balance : </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <li style={{ display: 'inline' }}><input style={{ border: '1px solid gray', fontSize: '12px', fontWeight: 'bold' }} value="124567"></input></li>
                                            </ul>
                                        </div>

                                    </div>







                                </div>
                            }
                            {showlenadena === 1 && <div style={{ height: '500px', width: '100%' }}>
                                <div style={{ height: '500px', width: '100%', paddingLeft: '5px' }}>
                                    <div className='lenadenaleftsection'>
                                        <div className='lenadenaleftsection_lenahead'>
                                            <div className='lenadenaleftsection_lenahead_left'>Downline Share P/L</div>
                                            {/* <div className='lenadenaleftsection_lenahead_right'>1200.00</div> */}
                                        </div>
                                        <div>
                                            <table id="table_transfer" class="table01 tab-transfer tab-banking" style={{ textAlign: 'left', border: '0.5px solid gray' }}>
                                                <tbody>
                                                    <tr>
                                                        <th width="20%">User </th>
                                                        <th width="15%">Balance</th>
                                                        <th width="15%">Amount</th>
                                                        <th width="15%">Pay Mode</th>
                                                        <th width="20%">Action</th>
                                                        <th width="15%">Get</th>
                                                    </tr>
                                                </tbody>
                                                <tbody id="content">
                                                    {Collectionreposrt.map((item2, id2) => {
                                                        return (
                                                            <tr key={id2} id="akshayddl" main_userid="akshayddl" style={{ textAlign: 'left', border: '0.5px solid gray' }}>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >{item2.id}</td>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} className={`${item2.aboveBalance >= 0 ? "green" : "red"}`}>{(item2.aboveBalance).toFixed(2)}</td>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >
                                                                    <input className="biab_number" onfocus="clearThis(this)" type="text" defaultValue=" " id={"settle" + id2} onChange={(e) => { setdlbl(e.target.value); }} style={{ width: '80px', marginTop: '3px', height: '25px', marginLeft: '7px', textAlign: 'center', fontWeight: 'bold' }} />
                                                                </td>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >
                                                                    <select id={"mode" + id2} name="winner_team" onChange={(e) => fun_paymode(e, id2)} style={{ height: "25px", width: '80px', marginTop: '5px', }} >
                                                                        <option value="0">Select</option>
                                                                        <option value="1">Online</option>
                                                                        <option value="2">Cash </option>

                                                                    </select>

                                                                </td>

                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >
                                                                    <button className='buttonlenadena' onClick={() => { fun_settle_bal_downline(item2.id, id2) }}  >Settle</button>
                                                                </td>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >
                                                                    <button className='buttonlenadena' onClick={() => { setshowledger(0); setshowlenadena(-1); setshowsettlement(1); fun_gethistory(item2.id, id2) }}  >History</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>



                                            </table>
                                        </div>
                                    </div>
                                    <div className='lenadenarightsection'>
                                        <div className='lenadenaleftsection_lenahead'>
                                            <div className='lenadenaleftsection_lenahead_left'>Upline Share P/L</div>

                                        </div>

                                        <div>
                                            <table id="table_transfer" class="table01 tab-transfer tab-banking" style={{ textAlign: 'left', border: '0.5px solid gray' }}>
                                                <tbody>
                                                    <tr>
                                                        <th width="40%">User Detail </th>
                                                        <th width="20%">Balance</th>
                                                        <th width="40%">Action</th>
                                                    </tr>
                                                </tbody>
                                                <tbody id="content">

                                                    <tr id="akshayddl" main_userid="akshayddl" style={{ textAlign: 'left', border: '0.5px solid gray' }}>
                                                        <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >{uplineid}</td>
                                                        <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold', color: `${uplinepl >= 0 ? "red" : "green"}` }} >{uplinepl}</td>
                                                        <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold', display: 'flex' }} >
                                                            {/* <button className='buttonlenadena' onClick={() => { setshowledger(0); setshowlenadena(0); setshowsettlement(1) }} >Settlement</button> */}
                                                            <button className='buttonlenadena'>Download</button>
                                                        </td>
                                                    </tr>

                                                </tbody>




                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {showsettlement === 1 && <div style={{ height: '500px', width: '100%' }}>
                                <div style={{ height: '500px', width: '100%', paddingLeft: '5px' }}>
                                    <div className='lenadenaleftsection'>
                                        <div className='lenadenaleftsection_lenahead'>
                                            <div className='lenadenaleftsection_lenahead_left'>Transaction History</div>
                                        </div>
                                        <div style={{height:'500px'}}>
                                            <table id="table_transfer" class="table01 tab-transfer tab-banking" style={{ textAlign: 'left', border: '0.5px solid gray',overflowy:'scroll' }}>
                                                <tbody>
                                                    <tr>
                                                        <th width="20%">Agent Name </th>
                                                        <th width="20%">Trans Date</th>
                                                        <th width="20%">Amount</th>
                                                        <th width="20%">Pay Mode</th>
                                                        <th width="20%">Cre/Deb</th>
                                                    </tr>
                                                </tbody>
                                                <tbody id="content">
                                                    {Trans_histor.map((item3, index) => {

                                                        return (
                                                            <tr key={index} id="akshayddl" main_userid="akshayddl" style={{ textAlign: 'left', border: '0.5px solid gray' }}>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >{AgentId}</td>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >{item3.createdAt}</td>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >{item3.amount}</td>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >{item3.remark}</td>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold', color:(item3.amount>=0?'green':'red') }}>{item3.amount >= 0 ? 'Debit': 'Credit'}</td>
                                                               
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>



                                            </table>
                                        </div>
                                    </div>


                                </div>



                            </div>

                            }
                            {showlenadena === 0 && <div style={{ height: '500px', width: '100%' }}>
                                <div style={{ height: '500px', width: '100%', paddingLeft: '5px' }}>
                                    <div className='lenadenaleftsection'>
                                        <div className='lenadenaleftsection_lenahead'>
                                            <div className='lenadenaleftsection_lenahead_left'>Downline Share Details</div>
                                            {/* <div className='lenadenaleftsection_lenahead_right'>1200.00</div> */}
                                        </div>
                                        <div>
                                            <table id="table_transfer" class="table01 tab-transfer tab-banking" style={{ textAlign: 'left', border: '0.5px solid gray' }}>
                                                <tbody>
                                                    <tr>
                                                        <th width="30%">Downline Agent </th>
                                                        <th width="15%">DWL Share</th>
                                                        <th width="15%">Rest Share</th>
                                                        <th width="15%">Match Comm.</th>
                                                        <th width="15%">Sess Comm.</th>
                                                        <th width="30%">Action</th>
                                                    </tr>
                                                </tbody>
                                                <tbody id="content">
                                                    {Downlinelistagent.map((item, index) => {
                                                        return (
                                                            <tr key={index} id="akshayddl" main_userid="akshayddl" style={{ textAlign: 'left', border: '0.5px solid gray' }}>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >{item.id}</td>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >{item.share}</td>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >{item.myShare}</td>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >{item.matchComm}%</td>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >{item.sessionComm}%</td>
                                                                <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold', display: 'flex' }} >
                                                                    <button className='buttonlenadena'>Action</button>

                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>



                                            </table>
                                        </div>
                                    </div>
                                    {openmasteruplinepl === 1 &&
                                        <div className='lenadenarightsection'>
                                            <div className='lenadenaleftsection_lenahead'>
                                                <div className='lenadenaleftsection_lenahead_left'>Upline Share P/L</div>

                                            </div>

                                            <div>
                                                <table id="table_transfer" class="table01 tab-transfer tab-banking" style={{ textAlign: 'left', border: '0.5px solid gray' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th width="40%">User Detail </th>
                                                            <th width="20%">Balance</th>
                                                            <th width="40%">Action</th>
                                                        </tr>
                                                    </tbody>
                                                    <tbody id="content">

                                                        <tr id="akshayddl" main_userid="akshayddl" style={{ textAlign: 'left', border: '0.5px solid gray' }}>
                                                            <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold' }} >{uplineid}</td>
                                                            <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold', color: `${uplinepl < 0 ? "red" : "green"}` }} >{uplinepl.toFixed(2)}</td>
                                                            <td style={{ borderTop: '1px solid #928686', fontWeight: 'bold', display: 'flex' }} >
                                                                <button className='buttonlenadena'>Download</button>
                                                            </td>
                                                        </tr>

                                                    </tbody>




                                                </table>
                                            </div>
                                        </div>}

                                </div>
                            </div>}
                        </div>

                    </div>







                </div>
            </div>

        </React.Fragment>
    )
}



        {/* <div style={{ height: '500px', width: '100%' }}>
                                <div style={{ height: '100px', width: '100%', paddingLeft: '10px' }}>
                                    <h2>Transaction</h2>

                                    <div class="function-wrap">
                                        <ul class="input-list">

                                            <li><label>Entry Type</label></li>
                                            <li>
                                                <select id="func_sports" onChange={(e) => { changeEvent(e); }}>
                                                    <option value="100" selected="selected">All</option>
                                                    <option value="1">All</option>
                                                    <option value="2">Settlement</option>

                                                </select>
                                            </li>
                                            <li><label>Period</label>
                                            </li>
                                            <li class="input-list" style={{ display: 'inline-flex' }}>

                                                <DatePicker
                                                    selectsStart
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={startDate}
                                                    placeholderText="YYYY-MM-DD"
                                                    className="cal-input"
                                                    onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }}
                                                />
                                                <input id="startTime" disabled="true" class="time-input disable" type="text" placeholder="09:00" maxlength="5" />

                                                <DatePicker
                                                    selectsEnd
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="YYYY-MM-DD"
                                                    selected={endDate}
                                                    onChange={(date) => { setendDate(date); seteDate(moment(date).format("YYYY-MM-DD")); }}
                                                    className="cal-input"
                                                />
                                                <input id="endTime" disabled="true" class="time-input disable" type="text" placeholder="08:59" maxlength="5" />


                                            </li>
                                            <li><a id="getPL" class="btn-send">Get P &amp; L</a></li>



                                        </ul>


                                    </div>
                                    <div style={{ height: '40px', width: '100%', backgroundColor: 'whitesmoke' }}>
                                        <ul>
                                            <li style={{ display: 'inline', fontSize: '20px', fontWeight: 'bold' }}>Lena : </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <li style={{ display: 'inline' }}><input style={{ border: '1px solid gray', fontSize: '12px', fontWeight: 'bold' }} value="0.00"></input></li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <li style={{ display: 'inline', fontSize: '20px', fontWeight: 'bold' }}>Dena : </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <li style={{ display: 'inline' }}><input style={{ border: '1px solid gray', fontSize: '12px', fontWeight: 'bold' }} value="0.00"></input></li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <li style={{ display: 'inline', fontSize: '20px', fontWeight: 'bold' }}>Balance : </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <li style={{ display: 'inline' }}><input style={{ border: '1px solid gray', fontSize: '12px', fontWeight: 'bold' }} value="124567"></input></li>
                                        </ul>
                                    </div>
                                    <div style={{ height: '40px', width: '100%', backgroundColor: 'whitesmoke' }}>
                                        <ul>
                                            <li style={{ display: 'inline', fontSize: '20px', fontWeight: 'bold' }}>Lena : </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <li style={{ display: 'inline' }}><input style={{ border: '1px solid gray', fontSize: '12px', fontWeight: 'bold' }} value="0.00"></input></li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <li style={{ display: 'inline', fontSize: '20px', fontWeight: 'bold' }}>Dena : </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <li style={{ display: 'inline' }}><input style={{ border: '1px solid gray', fontSize: '12px', fontWeight: 'bold' }} value="0.00"></input></li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <li style={{ display: 'inline', fontSize: '20px', fontWeight: 'bold' }}>Balance : </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <li style={{ display: 'inline' }}><input style={{ border: '1px solid gray', fontSize: '12px', fontWeight: 'bold' }} value="124567"></input></li>
                                        </ul>
                                    </div>

                                </div>

                            </div> */}